<template>
  <div class="memberDetail">
    <div class="memberDetail-wrap">
      <div class="memberDetail-wrap-1">
        <div>
          <img src="../../../assets/kaorou.jpg" alt />
        </div>
        <div class="memberDetail-wrap-1-c">
          <p class="hua">花儿都开了</p>
          <div class="memberDetail-wrap-1-c-c">
            <p>太原市</p>
            <p>直属达人</p>
          </div>
          <div class="memberDetail-wrap-1-c-b">
            <p>注册达人时间：2020-08-24 18:12:52</p>
          </div>
        </div>
      </div>
      <ul class="memberDetail-wrap-2">
        <li>
          <p>1</p>
          <p>贡献订单</p>
        </li>
        <li>
          <p>1</p>
          <p>成交金额(元)</p>
        </li>
        <li>
          <p>1</p>
          <p>贡献收益(元)</p>
        </li>
        <li>
          <p>1</p>
          <p>团队成员</p>
        </li>
      </ul>
    </div>
    <div class="myteam-wrap-top-1">
      <img src="../../../assets/tongxun.png" alt />
      <p>有队员想要联系你，添加你的联系方式</p>
      <p>已邀请</p>
    </div>
    <div class="myteam-wrap-top-2">
      <p class="p10">贡献订单</p>
      <div class="myteam-wrap-top-2-list">
        <div class="myteam-wrap-top-2-list-top">
          <div class="myteam-wrap-top-2-list-top-l">
            <img src="../../../assets/kaorou.jpg" alt />
            <p>自购订单</p>
          </div>
          <div class="myteam-wrap-top-2-list-top-r">
            <p>已到账</p>
          </div>
        </div>
        <div class="myteam-wrap-top-2-list-bottom">
          <div class="myteam-wrap-top-2-list-bottom-l">
            <img src="../../../assets/kaorou.jpg" alt />
          </div>
          <p class="myteam-wrap-top-2-list-bottom-c">代餐豆干无糖精无油无添加减 低卡脂小零食健身解馋热...</p>
          <div class="myteam-wrap-top-2-list-bottom-r">
            <p>￥45.50</p>
            <p>x1</p>
          </div>
        </div>

        <div class="myteam-wrap-top-2-list-footer">
          <p v-on:click="changeFoldState">
            {{brandFold?'展开':'收起'}}
            <svg
              t="1598498850083"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="2828"
              width="14"
              height="8"
            >
              <path
                d="M533.931498 739.588415c-12.692067 12.692067-33.187867 12.773931-45.778626 0.184195L74.382081 326.000795c-12.590759-12.590759-12.507872-33.086559 0.184195-45.778626l0 0c12.692067-12.692067 33.187867-12.773931 45.778626-0.184195l413.771815 413.771815C546.70543 706.400548 546.623565 726.896348 533.931498 739.588415L533.931498 739.588415z"
                p-id="2829"
                fill="#adadad"
              />
              <path
                d="M947.851693 281.262872c12.692067 12.692067 12.773931 33.187867 0.184195 45.778626L534.264073 740.812289c-12.590759 12.590759-33.086559 12.507872-45.778626-0.184195l0 0c-12.692067-12.692067-12.773931-33.187867-0.184195-45.778626l413.770791-413.770791C914.663826 268.48894 935.159626 268.570805 947.851693 281.262872L947.851693 281.262872z"
                p-id="2830"
                fill="#adadad"
              />
            </svg>
          </p>
        </div>
        <!-- //下拉 -->
        <div class="myteam-wrap-top-2-list-down" v-show="boxshow">
          <div class="myteam-wrap-top-2-list-down-1">
            <p>购买人:李**</p>
            <p>
              实付:
              <span>￥45.50</span>
            </p>
          </div>
          <p>下单时间:2020-08-25 09:38:25</p>
          <p>订单编号:465312486513</p>
          <p>收入详情:饭佣2.5元</p>
        </div>
        <div class="myteam-wrap-top-2-list-gongxian">
            <p>贡献:</p>
            <p>2.5元</p>
        </div>
        <!-- <div class="myteam-wrap-top-2-list-footer">
          <p>
            收起
            <svg
              t="1598499723908"
              class="icon"
              viewBox="0 0 1024 1024"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              p-id="3566"
              width="14"
              height="8"
            >
              <path
                d="M832.522014 669.951405c0-8.782014-3.235694-17.565051-9.708106-24.26873L535.638373 348.198354c-12.932544-13.408381-33.9226-13.408381-46.855144 0l-287.172465 297.485345c-12.9438 13.408381-12.9438 35.130102 0 48.53746 12.932544 13.408381 33.9226 13.408381 46.855144 0l263.744893-273.215592 263.747963 273.215592c12.932544 13.408381 33.9226 13.408381 46.855144 0C829.28632 687.516456 832.522014 678.734442 832.522014 669.951405z"
                p-id="3567"
                fill="#cdcdcd"
              />
            </svg>
          </p>
        </div> -->
      </div>
    </div>
  </div>
</template>
<style lang="scss">
.memberDetail {
  width: 100%;
  height: 14rem;
  background: #f6f6f6;
  .my-top {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0.3rem;
    box-sizing: border-box;
    font-size: 0.38rem;
    background: #f4f4f4;
  }
  .memberDetail-wrap {
    width: 100%;
    height: 3.4rem;
    background-image: url("../../../assets/duiyuan.jpg");
    .memberDetail-wrap-1 {
      padding: 0.46rem 0 0.47rem 0.22rem;
      box-sizing: border-box;
      display: flex;
      div:nth-child(1) {
        margin-right: 0.22rem;
        img {
          width: 1.3rem;
          height: 1.3rem;
          border-radius: 50%;
        }
      }
      div:nth-child(2) {
        .hua {
          font-size: 0.3rem;
          color: #fff;
          margin-bottom: 0.21rem;
        }
        .memberDetail-wrap-1-c-c {
          display: flex;
          margin-bottom: 0.15rem;
          p:nth-child(1) {
            width: 0.97rem;
            height: 0.33rem;
            line-height: 0.33rem;
            text-align: center;
            font-size: 0.23rem;
            margin-right: 0.12rem;
            color: #ff5400;
            background: #ffe6da;
            border-radius: 0.08rem;
          }
          p:nth-child(2) {
            width: 0.97rem;
            height: 0.33rem;
            line-height: 0.33rem;
            text-align: center;
            font-size: 0.23rem;
            margin-right: 0.12rem;
            color: #ff5400;
            background: #bd9758;
            border-radius: 0.06rem;
          }
        }
        .memberDetail-wrap-1-c-b {
          font-size: 0.2rem;
          color: #fff;
        }
      }
    }
    .memberDetail-wrap-2 {
      width: 100%;
      height: 1.04rem;
      background: #ff761a;
      opacity: 0.3;
      display: flex;
      li {
        width: 25%;
        color: #fff;
        font-size: 0.24rem;
        z-index: 100;
        text-align: center;
        padding-top: 0.2rem;
        p:nth-child(1) {
          font-size: 0.24rem;
          margin-bottom: 0.1rem;
        }
      }
    }
  }
  .myteam-wrap-top-1 {
    width: 100%;
    height: 1.02rem;
    background: #fff;
    padding: 0.22rem 0.23rem;
    box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 0.12rem;
    img {
      width: 0.38rem;
      height: 0.42rem;
    }
    p:nth-child(2) {
      font-size: 0.25rem;
      color: #ff5400;
      margin-left: 0.18rem;
    }
    p:nth-child(3) {
      width: 1.46rem;
      height: 0.57rem;
      font-size: 0.25rem;
      background: #dedede;
      margin-left: 0.6rem;
      color: #fff;
      text-align: center;
      line-height: 0.57rem;
      border-radius: 0.23rem;
    }
  }
  .myteam-wrap-top-2 {
    width: 7.02rem;
    margin: 0 auto;
    .p10 {
      font-size: 0.3rem;
      height: 1.23rem;
      line-height: 1.23rem;
    }
    .myteam-wrap-top-2-list {
      width: 100%;
      border-radius: 0.07rem;
      background: #fff;
      padding: 0.2rem;
      box-sizing: border-box;
      .myteam-wrap-top-2-list-top {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .myteam-wrap-top-2-list-top-l {
          display: flex;
          align-items: center;
          img {
            width: 0.63rem;
            height: 0.63rem;
            border-radius: 50%;
            margin-right: 0.18rem;
          }
          p {
            width: 1.15rem;
            height: 0.42rem;
            line-height: 0.42rem;
            border-radius: 0.07rem;
            font-size: 0.23rem;
            background: #ffedd8;
            color: #ff5c03;
            text-align: center;
          }
        }
        .myteam-wrap-top-2-list-top-r {
          p {
            font-size: 0.22rem;
            text-align: center;
          }
        }
      }
      .myteam-wrap-top-2-list-bottom {
        margin-top: 0.46rem;
        display: flex;
        .myteam-wrap-top-2-list-bottom-l {
          img {
            width: 1.15rem;
            height: 0.93rem;
            display: block;
          }
        }
        .myteam-wrap-top-2-list-bottom-c {
          margin-left: 0.22rem;
          font-size: 0.27rem;
          line-height: 0.5rem;
        }
        .myteam-wrap-top-2-list-bottom-r {
          line-height: 0.5rem;
          p:nth-child(1) {
            font-size: 0.23rem;
          }
          p:nth-child(2) {
            font-size: 0.23rem;
            color: #b3b3b3;
            margin-left: 0.6rem;
          }
        }
      }
      .myteam-wrap-top-2-list-footer {
        font-size: 0.23rem;
        color: #c4c4c4;
        display: flex;
        justify-content: flex-end;
        margin-top: 0.27rem;
      }
      .myteam-wrap-top-2-list-down{
          width: 100%;
          margin-top: 0.53rem;
          .myteam-wrap-top-2-list-down-1{
              font-size: 0.24rem;
              color: #adadad;
              display: flex;
              justify-content: space-between;
              p:nth-child(2){
                  span{
                      color: #000;
                  }
              }
          }
         p{
                  font-size: 0.13rem;
                  color: #b8b8b8;
         }
      }
      .myteam-wrap-top-2-list-gongxian{
          height: 1.01rem;
          line-height: 1.01rem;
          display: flex;
          justify-content: flex-end;
          p:nth-child(1){
              font-size: 0.22rem;
          }
           p:nth-child(2){
              font-size: 0.23rem;
              color: #ff5400;
          }
      }
    }
  }
}
</style>
<script>
export default {
  data() {
    return {
         brandFold: true,
         boxshow:false,
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    changeFoldState() {
        this.brandFold = !this.brandFold,
        this.boxshow=!this.boxshow
    }
  },
    created() {
       document.title = "队员详情";
    },
};
</script>